import React from 'react';
import { Container, Row, Col, Alert, Modal, Button } from "react-bootstrap";
import { getRegistros, getRegistro, deleteRegistro } from "../../Providers/ApiProvider";
import DataTable from "../../Components/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import { Chart } from "react-google-charts";

const Dashboard = ({state, setState}) => {
    const navigate = useNavigate();

    const [show, setShow] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [grafica, setGrafica] = React.useState([['Fecha', 'Indicador 1', 'Indicador 2', 'Indicador 3', 'Indicador 4', 'Indicador 5', 'Indicador 6']]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    React.useEffect(() => {
        getRegistros().then(response => {
            if(response.status === 200) {
                setState({
                    ...state,
                    registros: response.data,
                });

                let registros = response.data;
                let temp_grafica = [['Fecha', 'Indicador 1', 'Indicador 2', 'Indicador 3', 'Indicador 4', 'Indicador 5', 'Indicador 6']];
                registros.forEach(registro => {
                    let indicadores = [];
                    indicadores.push(registro.fecha);
                    indicadores.push(registro.consumo_etanol_transporte);
                    indicadores.push(registro.consumo_electricidad_transporte);
                    indicadores.push(registro.emisiones_evitadas_electromovilidad);
                    indicadores.push(registro.emisiones_evitadas_etanol);
                    indicadores.push(registro.emisiones_evitadas_generacion_electricidad);
                    indicadores.push(registro.indice_tecnologias_limpias);
                    // Verificamos que el registro no esté en la gráfica para agregarlo
                    if(temp_grafica.filter(reg => reg[0] === registro.fecha).length === 0) {
                        temp_grafica.push(indicadores);
                    }
                });
                setGrafica(temp_grafica);
            } else {
                setError(true);
                setMessage('Ha ocurrido un error, por favor, contacta con el proveedor.');
                setLoading(false);
            }
        });
    }, []);

    const handleDelete = (id) => {
        handleShow();
        setState({
            ...state,
            registro_actual: state.registros.find(registro => registro.id === id),
            registro_id: id,
        });
    }

    const handleNew = () => {
        setState({
            ...state,
            registro_actual: null,
            registro_id: 0,
        });
        navigate('/registro');
    };

    const handleEdit = (id) => {
        getRegistro(id).then(response => {
            if(response.status === 200) {
                let registro = response.data;
                setState({
                    ...state,
                    registro_actual: registro,
                    registro_id: registro.id,
                });
                navigate('/registro', { replace: true });
            } else {
                setError(true);
                setMessage('Ha ocurrido un error, por favor, contacta con el proveedor.');
                setLoading(false);
            }
        });
    }

    const handleDeleteConfirm = () => {
        deleteRegistro(state.registro_id).then(response => {
            if(response.status === 200) {
                let registros = state.registros.filter(registro => registro.id !== state.registro_id);
                setState({
                    ...state,
                    registros: registros,
                    registro_actual: null,
                    registro_id: 0,
                });
                setShow(false);
                setMessage('Registro eliminado correctamente.');
                setTimeout(() => {
                    setMessage('');
                }, 3000);
            } else {
                setError(true);
                setMessage('Ha ocurrido un error, por favor, contacta con el proveedor.');
                setLoading(false);
            }
        });
    };

    const modal = () => {
        return(
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>MRV</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Est&aacute;s seguro de eliminar el registro: {state.registro_actual!= null && state.registro_actual.fecha}? </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleDeleteConfirm}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <Container>
            <Row className="justify-content-center mt-4 mb-4">
                <Col lg={12}>
                    <Button variant="primary" onClick={handleNew}>
                        + Nuevo registro
                    </Button>
                </Col>
            </Row>
            <Row className="justify-content-center mt-4 mb-4">
                <Col lg={12} className="text-center">
                    {(loading) ? 'Cargando...' : (error) ?
                    (<Alert variant="danger">
                            {message}
                        </Alert>) : 
                    (state.registros != null && state.registros.length > 0) ?
                    (<DataTable
                        data={state.registros}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                    />) : (<Alert variant="info">
                            No se obtuvieron registros de la base de datos.
                        </Alert>)}
                </Col>
            </Row>
            <Row className="justify-content-center mt-4 mb-4">
                <Col lg={12} className="text-center">
                    <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="BarChart"
                        loader={<div>Cargando gr&aacute;fica...</div>}
                        data={grafica}
                        options={{
                            hAxis: {
                                title: 'Indicadores',
                            },
                            vAxis: {
                                title: 'Fecha',
                            },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />
                </Col>
            </Row>
            {modal()}
        </Container>
    );
};

export default Dashboard;