import React from 'react';
import { Navigate } from 'react-router-dom';
import Navbar from './Components/Navigation/Navigation';
import { isLogged } from './Sesion';

const PrivateRoute = ({ children }) => {
    if(isLogged()){
        return (
            <>
                <Navbar />
                <div id="main-content">
                    {children}
                </div>
            </>
            );
    }

    return <Navigate to="/login" />;
};

export default PrivateRoute;