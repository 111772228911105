import axios from 'axios';
import { login, getToken, isLogged } from '../Sesion';

const baseUrl = 'https://api.mrv.world/public/api';

export const signin = (email, password) => {
    // Hacer la petición al servidor para obtener el token
    return axios.post(baseUrl + '/login', {
        email,
        password
    }).then(response => {
        // Guardar el token en el localStorage
        login(response.data.access_token,
                response.data.expires_in,
                response.data.token_type,
                response.data.userName,
                response.data.userId,
                response.data.rol);
        return response.data;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const getRegistros = () => {
    // Solicitud GET al servidor para obtener los registros con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.get(baseUrl + '/registro', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const getRegistro = (id) => {
    // Solicitud GET al servidor para obtener un registro con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.get(baseUrl + '/registro/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const postRegistro = (registro) => {
    // Solicitud POST al servidor para crear un registro con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.post(baseUrl + '/registro', registro, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const putRegistro = (registro) => {
    // Solicitud PUT al servidor para actualizar un registro con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.put(baseUrl + '/registro/' + registro.id, registro, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const deleteRegistro = (id) => {
    // Solicitud DELETE al servidor para eliminar un registro con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.delete(baseUrl + '/registro/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const getRoles = () => {
    // Solicitud GET al servidor para obtener los roles con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.get(baseUrl + '/rol', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const getRol = (id) => {
    // Solicitud GET al servidor para obtener un rol con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.get(baseUrl + '/rol/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const postRol = (rol) => {
    // Solicitud POST al servidor para crear un rol con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.post(baseUrl + '/rol', rol, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const putRol = (rol) => {
    // Solicitud PUT al servidor para actualizar un rol con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.put(baseUrl + '/rol/' + rol.id, rol, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const deleteRol = (id) => {
    // Solicitud DELETE al servidor para eliminar un rol con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.delete(baseUrl + '/rol/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const getUsuarios = () => {
    // Solicitud GET al servidor para obtener los usuarios con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.get(baseUrl + '/usuario', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const getUsuario = (id) => {
    // Solicitud GET al servidor para obtener un usuario con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.get(baseUrl + '/usuario/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {        
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const postUsuario = (usuario) => {
    // Solicitud POST al servidor para crear un usuario con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.post(baseUrl + '/usuario', usuario, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {        
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const putUsuario = (usuario) => {
    // Solicitud PUT al servidor para actualizar un usuario con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.put(baseUrl + '/usuario/' + usuario.id, usuario, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {        
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const deleteUsuario = (id) => {
    // Solicitud DELETE al servidor para eliminar un usuario con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.delete(baseUrl + '/usuario/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {        
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const getTags = () => {
    // Solicitud GET al servidor para obtener los tags con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.get(baseUrl + '/tag', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {        
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const getTag = (tag) => {
    // Solicitud GET al servidor para obtener un tag con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.get(baseUrl + '/tag/' + tag, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {        
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const deleteTag = (tag) => {
    // Solicitud DELETE al servidor para eliminar un tag con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.delete(baseUrl + '/tag/' + tag, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {        
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const getInfos = () => {
    // Solicitud GET al servidor para obtener los infos con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.get(baseUrl + '/info', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {        
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const getInfo = (id) => {
    // Solicitud GET al servidor para obtener un info con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.get(baseUrl + '/info/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {        
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const postInfo = (info) => {
    // Solicitud POST al servidor para crear un info con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.post(baseUrl + '/info', info, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {        
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export const putInfo = (info) => {
    // Solicitud PUT al servidor para actualizar un info con headers ContentType y Authorization
    if(!isLogged) return false;
    return axios.put(baseUrl + '/info/' + info.id, info, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(response => {        
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

