import { Navigate } from 'react-router-dom';

export const isLogged = () => {
    // Comprueba si existen cookies nada más cargar la página
    if (document.cookie === "" || document.cookie === undefined || document.cookie === null) {
        return false;
    }
    // obtiene las cookies relacionadas con la sesion
    var token = document
        .cookie
        .split('; ')
        .find(row => row.startsWith('access_token'))
        .split('=')[1];
    var expiration = document
        .cookie
        .split('; ')
        .find(row => row.startsWith('expires_in'))
        .split('=')[1];
    var token_type = document
        .cookie
        .split('; ')
        .find(row => row.startsWith('token_type'))
        .split('=')[1];
    var username = document
        .cookie
        .split('; ')
        .find(row => row.startsWith('username'))
        .split('=')[1];
    var userid = document
        .cookie
        .split('; ')
        .find(row => row.startsWith('userid'))
        .split('=')[1];
    var rol = document
        .cookie
        .split('; ')
        .find(row => row.startsWith('rol'))
        .split('=')[1];

    // comprueba la existencia de todas las cookies y que la sesion no haya expirado de lo contrario redirije al usuario a la pagina de login
    if (token && token!='null' 
    && expiration && expiration !='null' 
    && username && username != 'null'
    && userid && userid != 'null'
    && rol && rol != 'null') {
        var ahora = Date.now();
        var expiracion_date = new Date(expiration).getTime();
        if (ahora < expiracion_date) {
            return true;
        }
    }

    return false;
}

export const getUserName = () => {
    // obtiene el nombre de usuario de la cookie
    return document
        .cookie
        .split('; ')
        .find(row => row.startsWith('username'))
        .split('=')[1];
}

export const getUserId = () => {
    // obtiene el id del usuario de la cookie
    return document
        .cookie
        .split('; ')
        .find(row => row.startsWith('userid'))
        .split('=')[1];
}

export const getToken = () => {
    // obtiene el token de la cookie
    return document
        .cookie
        .split('; ')
        .find(row => row.startsWith('access_token'))
        .split('=')[1];
}

export const getRol = () => {
    var rol = document
        .cookie
        .split('; ')
        .find(row => row.startsWith('rol'))
        .split('=')[1];
    
    switch(rol){
        case '1':
            return 'su';
        case '2':
            return 'admin';
        default:
            return 'user';
    }
}

export const signout = () => {
    // destruye todas las cookies relacionadas con la sesion y redirige al usuario a la pagina de login
    document.cookie = "access_token=" + null;
    document.cookie = "expires_in=" + null;
    document.cookie = "token_type=" + null;
    document.cookie = "username=" + null;
    document.cookie = "userid=" + null;
    document.cookie = "rol=" + null;
}

export const login = (token, expiration, token_type, username, userid, rolid) => {
    // crea las cookies relacionadas con la sesion y redirige al usuario al dashboard
    document.cookie = "access_token=" + token;
    document.cookie = "expires_in=" + expiration;
    document.cookie = "token_type=" + token_type;
    document.cookie = "username=" + username;
    document.cookie = "userid=" + userid;
    document.cookie = "rol=" + rolid;

    return <Navigate to="/dashboard" />
}