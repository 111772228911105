import React from "react";
import { Navigate, Link } from "react-router-dom";
import { isLogged } from "../../Sesion";
import { Container, Row, Col, Form, Button, InputGroup, Spinner, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { signin } from "../../Providers/ApiProvider";
import Gobierno from './Gobierno.png';
import Style from "./Login.module.css";

const Login = ({state, setState}) => {
    const [ShowPassword, setShowPassword] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [validEmail, setValidEmail] = React.useState(true);
    const [validPassword, setValidPassword] = React.useState(true);
    const [cargando, setCargando] = React.useState(false);
    const [error, setError] = React.useState(false);

    // Verificar si el usuario ya está autenticado
    const isAuthenticated = isLogged();

    // Si el usuario ya está autenticado, redirigir a la página principal
    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(email === ""){
            setValidEmail(false);
        }

        if(password === ""){
            setValidPassword(false);
        }

        if(email !== "" && password !== ""){
            // Hacer la petición al servidor
            setCargando(true);
            signin(email, password).then(response => {
                if(response.access_token !== undefined || response.access_token !== null){
                    setState({
                        Token: response.access_token,
                        UserName: response.userName,
                        UserId: response.userId,
                        Rol: response.rol,
                        Logged: true,
                        indicador1: response.indicador1,
                        indicador2: response.indicador2,
                        indicador3: response.indicador3,
                        indicador4: response.indicador4,
                        indicador5: response.indicador5,
                        indicador6: response.indicador6,
                    });
                    setCargando(false);
                    setError(false);
                }else{
                    setError(true);
                    setCargando(false);
                }
            }).catch(error => {
                console.log(error);
                setError(true);
                setCargando(false);
            });
        }
    };

    const handleShowPassword = () => {
        setShowPassword(!ShowPassword);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    return (
        <Container fluid style={{ background: "#F9F9F9"}}>
            <Row className="row vh-100 align-items-center justify-content-center">
                <Col lg={5} className={["d-none d-md-block mg-4"]}>
                    <Image src={Gobierno} className="img-fluid" />
                </Col>
                <Col lg={{ span: 3, offset: 1}} className="mt-4">
                    <div className={Style.margin_top_login}>
                        <h3>Iniciar Sesi&oacute;n</h3>
                        <Form onSubmit={(event) => handleSubmit(event)}>
                            <Form.Group controlId="formBasicEmail" className="mb-2">
                                <Form.Label>Correo electr&oacute;nico</Form.Label>
                                <Form.Control type="email" placeholder="Ingresa tu correo" 
                                    value={email} onChange={(event) => handleEmailChange(event)}
                                    required
                                />
                                <Form.Control.Feedback>
                                    <FontAwesomeIcon icon={faTriangleExclamation} className="text-danger" />
                                    &nbsp;El correo electr&oacute;nico no es v&aacute;lido o no existe.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword" className="mb-3">
                                <Form.Label>Contrase&ntilde;a</Form.Label>
                                <InputGroup>
                                    <Form.Control type={ShowPassword?"text":"password"} placeholder="Contrase&ntilde;a"
                                        value={password} onChange={(event) => handlePasswordChange(event)}
                                    />
                                    <Button variant="outline-secondary" onClick={handleShowPassword}>
                                        <FontAwesomeIcon className={Style.showPasswordIcon} icon={ShowPassword ? faEye : faEyeSlash} />
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                {!cargando?"Iniciar Sesión":(<><Spinner animation="border" variant="light" size="sm" /> Iniciando sesi&oacute;n...</>)}
                            </Button>
                            {(validEmail && validPassword)?"":(
                                <Form.Text className="text-danger">
                                    <FontAwesomeIcon icon={faTriangleExclamation} className="text-danger" />
                                    &nbsp;Todos los campos son obligatorios.
                                </Form.Text>
                            )}
                            
                            {error && (
                                <>
                                    <hr/>
                                    <Form.Text className="text-danger">
                                        <FontAwesomeIcon icon={faTriangleExclamation} className="text-danger" />
                                        &nbsp;El correo electr&oacute;nico o la contrase&ntilde;a son incorrectos.
                                    </Form.Text>
                                </>
                            )}
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;