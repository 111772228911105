import React from 'react';
import { Container, Row, Col, Card, Form, InputGroup, Button, FloatingLabel, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faQuestionCircle, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { getUserId } from '../../Sesion';
import { getInfo, postInfo } from '../../Providers/ApiProvider';
import { useNavigate } from "react-router-dom";

const Archivo = () => {
    const navigate = useNavigate();

    const [tag, setTag] = React.useState('');
    const [rawTable, setRawTable] = React.useState('');
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [success, setSuccess] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    
    /*
        Un archivo consta de:
        - Un tag que es un string llamado tag
        - El contenido del archivo que sera un string con un textarea, se pegaran documentos de excel o tablas en general así que debe parsearse los caracteres especiales como tabulaciones y saltos de línea, este campo se llama rawTable
        - el id del usuario que lo sube, este campo se llama idUsuario
        Este es el formulario de nuevo archivo
    */
    const handleSave = () => {
        // Validamos que los campos no esten vacios
        if(tag === '' || rawTable === '') {
            setError(true);
            setErrorMessage('Los campos no pueden estar vacios.');
            return;
        } else {
            let idUsuario = getUserId();
            // Hacemos el post
            postInfo({
                tag: tag,
                rawTable: rawTable,
                idUsuario: idUsuario,
            }).then(response => {
                if(response.status === 201) {
                    setSuccess(true);
                    setError(false);
                    setSuccessMessage('Archivo guardado correctamente.');
                    setTag('');
                    setRawTable('');
                } else {
                    setSuccess(false);
                    setError(true);
                    setErrorMessage('Ha ocurrido un error, por favor, contacta con el proveedor.');
                }
                // regresar el scroll al inicio
                window.scrollTo(0, 0);
            });
        }
    }

    const handleBack = () => {
        // Regresamos a la pagina anterior
        navigate('/data');
    }

    return (
        <Container>
            <Row className="justify-content-center mt-4 mb-4">
                <Col lg={12}>
                    <Button variant="primary" onClick={handleBack}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Atr&aacute;s
                    </Button>
                </Col>
            </Row>
            {(error) ?
            (<Row className="justify-content-center mt-4 mb-4">
                <Col lg={12} className="text-center">
                    <Alert variant="danger">
                        {errorMessage}
                    </Alert>
                </Col>
            </Row>) : (success) ?
            (<Row className="justify-content-center mt-4 mb-4">
                <Col lg={12} className="text-center">
                    <Alert variant="success">
                        {successMessage}
                    </Alert>
                </Col>
            </Row>) : (<></>)}
            <Row className="justify-content-center mt-4 mb-4">
                <Col lg={12} className="text-center">
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                <FontAwesomeIcon icon={faSave} /> Nuevo archivo
                            </Card.Title>
                            <Form>
                                <Row className='justify-content-center'>
                                    <Col lg={6} className='text-center mb-2 mt-2'>
                                        <FloatingLabel controlId="floatingInput" label="Tag">
                                            <Form.Control type="text" placeholder="Tag" value={tag} onChange={(e) => setTag(e.target.value)} />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}  className='text-center mb-2 mt-2'>
                                        <FloatingLabel controlId="floatingInput" label="Contenido">
                                            <Form.Control as="textarea" rows={5} placeholder="Contenido" value={rawTable} onChange={(e) => setRawTable(e.target.value)} />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row className='justify-content-center'>
                                    <Col lg={6}  className='text-center mb-2 mt-2'>
                                        <Button variant="primary" onClick={handleSave}>
                                            <FontAwesomeIcon icon={faSave} /> Guardar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

};

export default Archivo;