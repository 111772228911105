import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getUserName, getRol } from "../../Sesion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";

function Navigation() {
    const username = getUserName();
    const rol = getRol();
    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" data-bs-theme="dark">
            <Container fluid>
                <div className="navbar-brand">
                    <Link to="/dashboard" className="nav-link">MRV</Link>
                </div>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">

                    <Nav className="me-auto">
                    <Link to ="/dashboard" className="nav-link">Inicio</Link>
                        <Link to="/data" className="nav-link">Data</Link>
                        
                        {(rol === 'su' || rol === 'admin') && (
                            <Link to="/usuarios" className="nav-link">Usuarios</Link>
                        )}
                    </Nav>
                    <Nav>
                        <Link to="/perfil" className="nav-link">
                            <FontAwesomeIcon icon={faCircleUser} size="1x" />&nbsp;
                            {username}
                        </Link>
                        <Link to="/logout" className="nav-link">Cerrar sesión</Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;