import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { getUsuario, postUsuario, putUsuario, getRoles } from '../../Providers/ApiProvider';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button, FloatingLabel, Alert } from 'react-bootstrap';

const Usuario = ({ state, setState }) => {
    const navigate = useNavigate();
    
    const [roles, setRoles] = React.useState([]);
    const [apodo, setApodo] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [rol, setRol] = React.useState('');
    const [password_confirm, setPasswordConfirm] = React.useState('');
    const [password_error, setPasswordError] = React.useState(false);
    const [status, setStatus] = React.useState(0);
    const [message, setMessage] = React.useState('');
    const [indicador1, setIndicador1] = React.useState(false);
    const [indicador2, setIndicador2] = React.useState(false);
    const [indicador3, setIndicador3] = React.useState(false);
    const [indicador4, setIndicador4] = React.useState(false);
    const [indicador5, setIndicador5] = React.useState(false);
    const [indicador6, setIndicador6] = React.useState(false);

    React.useEffect(() => {
        getRoles().then((response) => {
            setRoles(response.data);
        });
    }, []);

    React.useEffect(() => {
        if (state.usuario != null) {
            getUsuario(state.usuario).then((response) => {
                let usuario = response.data;
                console.log(usuario);
                setApodo(usuario.apodo);
                setEmail(usuario.email);
                setRol(usuario.rolId);
                setIndicador1(usuario.indicador1==='1'?true:false);
                setIndicador2(usuario.indicador2==='1'?true:false);
                setIndicador3(usuario.indicador3==='1'?true:false);
                setIndicador4(usuario.indicador4==='1'?true:false);
                setIndicador5(usuario.indicador5==='1'?true:false);
                setIndicador6(usuario.indicador6==='1'?true:false);
                setState({
                    ...state,
                    usuario: usuario
                })
            });
        }
    }, []);

    React.useEffect(() => {
        if (password !== password_confirm) {
            setPasswordError(true);
        }
        else {
            setPasswordError(false);
        }
    }, [password_confirm]);

    const handleSave = () => {
        if (state.usuario == null) {
            // Crear
            let usuario = {
                apodo: apodo,
                email: email,
                password: password,
                rolId: rol,
                indicador1: indicador1?1:0,
                indicador2: indicador2?1:0,
                indicador3: indicador3?1:0,
                indicador4: indicador4?1:0,
                indicador5: indicador5?1:0,
                indicador6: indicador6?1:0,
            };
            postUsuario(usuario).then((response) => {
                setStatus(response.status);
                if(response.status === 201){
                    setMessage('Usuario creado correctamente.');
                } else {
                    setMessage('No se pudo crear el usuario.');
                }
            });
        }
        else {
            // Editar
            let usuario = {
                id: state.usuario.id,
                apodo: apodo,
                email: email,
                password: password,
                rolId: rol,
                indicador1: indicador1?1:0,
                indicador2: indicador2?1:0,
                indicador3: indicador3?1:0,
                indicador4: indicador4?1:0,
                indicador5: indicador5?1:0,
                indicador6: indicador6?1:0,
            };
            putUsuario(usuario).then((response) => {
                setStatus(response.status);
                if(response.status === 200){
                    setMessage('Usuario editado correctamente.');
                } else {
                    setMessage('No se pudo editar el usuario.');
                }
            });
        }
        // reset scroll
        window.scrollTo(0, 0);
    };

    return (
        <Container>
            {((status === 201 || status === 200) && message !== '') && (
                <Row className='justify-content-md-center'>
                    <Col lg={6} className='mt-4'>
                        <Alert variant='success'>
                            {message}
                        </Alert>
                    </Col>
                </Row>
            )}
            {((status !== 201 && status !== 200) && message !== '') && (
                <Row className='justify-content-md-center'>
                    <Col lg={6} className='mt-4'>
                        <Alert variant='danger'>
                            {message}
                        </Alert>
                    </Col>
                </Row>
            )}
            <Row className='justify-content-md-center'>
                <Col lg={6} className='mt-2'>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col lg={1}>
                                    <Link to='/usuarios' className='btn btn-outline-primary'>
                                        <FontAwesomeIcon icon={faArrowLeftLong} />
                                    </Link>
                                </Col>
                                <Col>
                                    <h2>{state.usuario == null ? 'Nuevo Usuario' : 'Editar Usuario'}</h2>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Group className='mb-2'>
                                    <FloatingLabel label="Nombre de usuario">
                                        <Form.Control type="text" placeholder='Nombre de usuario'
                                            value={apodo} onChange={
                                                (e) => {
                                                    setApodo(e.target.value);
                                                }
                                            }
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className='mb-2'>
                                    <FloatingLabel label="Correo electrónico">
                                        <Form.Control type="email" placeholder='Correo electrónico'
                                            value={email} onChange={
                                                (e) => {
                                                    setEmail(e.target.value);
                                                }
                                            }
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className='mb-2'>
                                    <FloatingLabel label="Rol">
                                        <Form.Select aria-label="Rol" value={rol} onChange={
                                            (e) => {
                                                setRol(e.target.value);
                                            }
                                        }>
                                            <option value="">Seleccione un rol</option>
                                            {
                                                roles.map((rol) => {
                                                    return (
                                                        <option key={"rol_" + rol.id} value={rol.id}>{rol.nombre}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>
                                <hr />
                                <Form.Group className='mb-2'>
                                    <FloatingLabel label="Contraseña">
                                        <Form.Control type="password" placeholder='Contraseña'
                                            value={password} onChange={
                                                (e) => {
                                                    setPassword(e.target.value);
                                                }
                                            }
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className='mb-2'>
                                    <FloatingLabel label="Confirmar contraseña">
                                        <Form.Control type="password" placeholder='Confirmar contraseña'
                                            value={password_confirm} onChange={
                                                (e) => {
                                                    setPasswordConfirm(e.target.value);
                                                }
                                            }
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                                <hr />
                                <Form.Group className='mb-2'>
                                    <Form.Check type="checkbox" label="Consumo especifico de etanol para sector transporte" checked={indicador1} onChange={
                                        (e) => { setIndicador1(e.target.checked);} } />
                                    <Form.Check type="checkbox" label="Consumo especifico de electricidad para sector transporte" checked={indicador2} onChange={
                                        (e) => { setIndicador2(e.target.checked);} } />
                                    <Form.Check type="checkbox" label="Emisiones evitadas del sector transporte debido a electromovilidad" checked={indicador3} onChange={
                                        (e) => { setIndicador3(e.target.checked);} } />
                                    <Form.Check type="checkbox" label="Emisiones evitadas del sector transporte debido al uso de etanol en mezcla" checked={indicador4} onChange={
                                        (e) => { setIndicador4(e.target.checked);} } />
                                    <Form.Check type="checkbox" label="Emisiones evitadas dentro del sector generación de electricidad" checked={indicador5} onChange={
                                        (e) => { setIndicador5(e.target.checked);} } />
                                    <Form.Check type="checkbox" label="Índice de tecnologías limpias" checked={indicador6} onChange={
                                        (e) => { setIndicador6(e.target.checked);} } />
                                </Form.Group>
                                {
                                    password_error &&
                                    <Alert variant='danger'>
                                        Las contraseñas no coinciden.
                                    </Alert>
                                }
                                <hr />
                                <Form.Group className='mb-2 text-end'>
                                    {(!password_error && apodo !== '' && email !== '' && rol !== '')?
                                    (
                                        <Button variant="primary btn-lg" onClick={handleSave}>
                                            <FontAwesomeIcon icon={faSave} /> Guardar
                                        </Button>
                                    ):(
                                        <Button variant="primary btn-lg" disabled>
                                            <FontAwesomeIcon icon={faSave} /> Guardar
                                        </Button>
                                    )}
                                        
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
};

export default Usuario;
