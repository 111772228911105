import React from 'react';
import { Container, Row, Col, Card, Form, InputGroup, Button, FloatingLabel, Alert, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faQuestionCircle, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { postRegistro, putRegistro } from '../../Providers/ApiProvider';
import { getUserId } from '../../Sesion';

const Registro = ({ state, setState }) => {
    const [fecha, setFecha] = React.useState('');
    const [consumo_etanol, setConsumo_etanol] = React.useState('');
    const [consumo_electricidad, setConsumo_electricidad] = React.useState('');
    const [emisiones_evitadas_electromovilidad, setEmisiones_evitadas_electromovilidad] = React.useState('');
    const [emisiones_evitadas_etanol, setEmisiones_evitadas_etanol] = React.useState('');
    const [emisiones_evitadas_generacion, setEmisiones_evitadas_generacion] = React.useState('');
    const [indice_tecnologias, setIndice_tecnologias] = React.useState('');
    const [error, setError] = React.useState(false);
    const [mensaje, setMensaje] = React.useState('');
    const [estimado, setEstimado] = React.useState([0,0,0,0,0,0]);
    const [disabled1, setDisabled1] = React.useState(false);
    const [disabled2, setDisabled2] = React.useState(false);
    const [disabled3, setDisabled3] = React.useState(false);
    const [disabled4, setDisabled4] = React.useState(false);
    const [disabled5, setDisabled5] = React.useState(false);
    const [disabled6, setDisabled6] = React.useState(false);

    const estimacion = {
        "2015": [11.49, 0.14, 31.38, 41901.00, 0, 0.42],
        "2016": [12.64, 0.35, 78.47, 49907.71, 0, 0.42],
        "2017": [13.79, 0.58, 130.26, 57117.28, 0, 0.46],
        "2018": [14.94, 0.87, 196.17, 64823.92, 0, 0.47],
        "2019": [18.72, 1.26, 282.49, 77531.45, 0, 0.46],
        "2020": [18.88, 1.49, 335.85, 84102.38, 0, 0.52],
        "2021": [18.69, 14, 3138.8, 97334.49, 0, 0.56],
        "2022": [22.99, 18, 4035.6, 131644.90, 683046.93, 0.60],
        "2023": [33.39, 27, 6053.4, 212277.40, 692537.76, 0.61],
        "2024": [42.54, 53, 11882.6, 302849.09, 867039.42, 0.63],
        "2025": [50.12, 127, 28473.4, 483801.42, 1091854, 0.66],
        "2026": [55.92, 283, 63448.6, 598301.09, 1177050, 0.67],
        "2027": [60.13, 502, 112548.4, 815398.92, 1248952.395058, 0.66],
        "2028": [63.39, 691, 154922.2, 969418.72, 1248952.395058, 0.68],
        "2029": [65.53, 977, 219043.4, 1146337.64, 1248952.395058, 0.68],
        "2030": [66.64, 1380, 309396, 1406868.92, 1336179.647058, 0.71]
    };

    React.useEffect(() => {
        if(fecha !== ''){
            let anio = fecha.split('-')[0];
            let estimado = estimacion[anio];
            if(estimado !== undefined){
                setEstimado(estimado);
            }
        }
    }, [fecha]);

    const handleSubmit = (event) => {
        event.preventDefault();
        let formato_fecha = fecha.split('-');
        let fecha_correcta = formato_fecha[0] + '-' + formato_fecha[1] + '-01';
        const registro = {
            "fecha": fecha_correcta,
            "consumo_etanol_transporte": consumo_etanol,
            "consumo_electricidad_transporte": consumo_electricidad,
            "emisiones_evitadas_electromovilidad": emisiones_evitadas_electromovilidad,
            "emisiones_evitadas_etanol": emisiones_evitadas_etanol,
            "emisiones_evitadas_generacion_electricidad": emisiones_evitadas_generacion,
            "indice_tecnologias_limpias": indice_tecnologias,
            "idUsuario": getUserId()
        };
        if(state.registro_actual == null) {
            postRegistro(registro).then(response => {
                console.log(response);
                if(response.status === 201) {
                    setError(false);
                    setMensaje('Registro creado correctamente');
                    setState(
                        {
                            ...state,
                            registro_actual: response.data
                        }
                    );
                    handleReset();
                } else {
                    setError(true);
                    setMensaje('Error al crear el registro');
                }
            });
        }

        if(state.registro_actual != null) {
            // Agregar a registro el id del registro actual
            registro.id = state.registro_actual.id;
            putRegistro(registro).then(response => {
                console.log(response);
                if(response.status === 200) {
                    setError(false);
                    setMensaje('Registro actualizado correctamente');
                    setState(
                        {
                            ...state,
                            registro_actual: response.data
                        }
                    );
                } else {
                    setError(true);
                    setMensaje('Error al actualizar el registro');
                }
            });
        }

        // reseteamos el scroll del navegador
        window.scrollTo(0, 0);
    };

    React.useState(() => {
        if(state.registro_actual != null){
            let registro_actual = state.registro_actual;
            let fecha = registro_actual.fecha.split('-');
            let fecha_mes = fecha[0] + '-' + fecha[1];
            setFecha(fecha_mes);
            setConsumo_etanol(registro_actual.consumo_etanol_transporte);
            setConsumo_electricidad(registro_actual.consumo_electricidad_transporte);
            setEmisiones_evitadas_electromovilidad(registro_actual.emisiones_evitadas_electromovilidad);
            setEmisiones_evitadas_etanol(registro_actual.emisiones_evitadas_etanol);
            setEmisiones_evitadas_generacion(registro_actual.emisiones_evitadas_generacion_electricidad);
            setIndice_tecnologias(registro_actual.indice_tecnologias_limpias);
        }

        if(state.Rol === 3 && state.indicador1 === "0"){
            setDisabled1(true);
        }
        if(state.Rol === 3 && state.indicador2 === "0"){
            setDisabled2(true);
        }
        if(state.Rol === 3 && state.indicador3 === "0"){
            setDisabled3(true);
        }
        if(state.Rol === 3 && state.indicador4 === "0"){
            setDisabled4(true);
        }
        if(state.Rol === 3 && state.indicador5 === "0"){
            setDisabled5(true);
        }
        if(state.Rol === 3 && state.indicador6 === "0"){
            setDisabled6(true);
        }
    }, []);

    const handleReset = (event) => {
        setFecha('');
        setConsumo_etanol('');
        setConsumo_electricidad('');
        setEmisiones_evitadas_electromovilidad('');
        setEmisiones_evitadas_etanol('');
        setEmisiones_evitadas_generacion('');
        setIndice_tecnologias('');
    };

    return(
        <Container>
            {(mensaje !== '' && !error) && (
                <Row>
                    <Col lg={12} className='mt-4'>
                        <Alert variant="success">
                            {mensaje}
                        </Alert>
                    </Col>
                </Row>
            )}
            {(mensaje !== '' && error) && (
                <Row>
                    <Col lg={12} className='mt-4'>
                        <Alert variant="danger">
                            {mensaje}
                        </Alert>
                    </Col>
                </Row>
            )}
            <Row>
                <Col lg={1} className='mb-4 mt-4'>
                    <Button variant="outline-secondary" onClick={() => window.history.back()}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Volver
                    </Button>
                </Col>
                <Col lg={11} className='mb-4 mt-4'>
                    <h1>{(state.registro_actual != null) ? 'Editar registro: ' + fecha : 'Nuevo registro '}</h1>
                    
                </Col>
            </Row>
            <Row>
                <Col lg={12} className='mb-4'>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className='mb-4'>
                                    {/*fecha seleccionando solo el mes*/}
                                    <Form.Control type="month" value={fecha} onChange={
                                        (e) => {
                                            setFecha(e.target.value);
                                        }
                                    } />
                                    <small>Mes y a&ntilde;o</small>
                                </Form.Group>
                                <InputGroup className='mb-4'>
                                    <InputGroup.Text>
                                        bl / kBEPS
                                    </InputGroup.Text>
                                    <FloatingLabel label="Consumo específico de etanol para sector transporte">
                                        {(disabled1?(<Form.Control type="number" placeholder='Consumo específico de etanol para sector transporte' disabled={true} />):(
                                        <Form.Control type="number" placeholder='Consumo específico de etanol para sector transporte'
                                            value={consumo_etanol} onChange={
                                                (e) => {
                                                    setConsumo_etanol(e.target.value);
                                                }
                                            }
                                        />))}
                                    </FloatingLabel>
                                </InputGroup>
                                {estimado[0] !== 0 && (<>
                                        <ProgressBar variant="success" now={consumo_etanol / estimado[0] * 100} label={(consumo_etanol / estimado[0] * 100).toFixed(2) + '%'} />
                                        <small>Estimado: {estimado[0]}</small>
                                        </>)}
                                        <br /><br />
                                <InputGroup className='mb-4'>
                                    <InputGroup.Text>
                                        GWh / kBEPS
                                    </InputGroup.Text>
                                    <FloatingLabel label="Consumo específico de electricidad para sector transporte">
                                        {(disabled2?(<Form.Control type="number" placeholder='Consumo específico de electricidad para sector transporte' disabled={true} />):(
                                        <Form.Control type="number" placeholder='Consumo específico de electricidad para sector transporte'
                                            value={consumo_electricidad} onChange={
                                                (e) => {
                                                    setConsumo_electricidad(e.target.value);
                                                }
                                            }
                                        />))}
                                    </FloatingLabel>
                                </InputGroup>
                                {estimado[1] !== 0 && (<>
                                        <ProgressBar variant="success" now={consumo_electricidad / estimado[1] * 100} label={(consumo_electricidad / estimado[1] * 100).toFixed(2) + '%'} />
                                        <small>Estimado: {estimado[1]}</small>
                                        </>)}
                                        <br /><br />
                                <InputGroup className='mb-4'>
                                    <InputGroup.Text>
                                        kg CO2 eq.
                                    </InputGroup.Text>
                                    <FloatingLabel label="Emisiones evitadas dentro del sector transporte debido a electromovilidad">
                                        {(disabled3?(<Form.Control type="number" placeholder='Emisiones evitadas dentro del sector transporte debido a electromovilidad' disabled={true} />):(
                                        <Form.Control type="number" placeholder='Emisiones evitadas dentro del sector transporte debido a electromovilidad'
                                            value={emisiones_evitadas_electromovilidad} onChange={
                                                (e) => {
                                                    setEmisiones_evitadas_electromovilidad(e.target.value);
                                                }
                                            }
                                        />))}
                                    </FloatingLabel>
                                </InputGroup>
                                {estimado[2] !== 0 && (<>
                                        <ProgressBar variant="success" now={emisiones_evitadas_electromovilidad / estimado[2] * 100} label={(emisiones_evitadas_electromovilidad / estimado[2] * 100).toFixed(2) + '%'} />
                                        <small>Estimado: {estimado[2]}</small>
                                        </>)}
                                        <br /><br />
                                <InputGroup className='mb-4'>
                                    <InputGroup.Text>
                                        kg CO2 eq.
                                    </InputGroup.Text>
                                    <FloatingLabel label="Emisiones evitadas dentro del sector transporte debido a uso de etanol en mezcla">
                                        {(disabled4?(<Form.Control type="number" placeholder='Emisiones evitadas dentro del sector transporte debido a uso de etanol en mezcla' disabled={true} />):(
                                        <Form.Control type="number" placeholder='Emisiones evitadas dentro del sector transporte debido a uso de etanol en mezcla'
                                            value={emisiones_evitadas_etanol} onChange={
                                                (e) => {
                                                    setEmisiones_evitadas_etanol(e.target.value);
                                                }
                                            }
                                        />))}
                                    </FloatingLabel>
                                </InputGroup>
                                {estimado[3] !== 0 && (<>
                                        <ProgressBar variant="success" now={emisiones_evitadas_etanol / estimado[3] * 100} label={(emisiones_evitadas_etanol / estimado[3] * 100).toFixed(2) + '%'} />
                                        <small>Estimado: {estimado[3]}</small>
                                        </>)}
                                        <br /><br />
                                <InputGroup className='mb-4'>
                                    <InputGroup.Text>
                                        kg CO2 eq.
                                    </InputGroup.Text>
                                    <FloatingLabel label="Emisiones evitadas dentro del sector generación de electricidad">
                                        {(disabled5?(<Form.Control type="number" placeholder='Emisiones evitadas dentro del sector generación de electricidad' disabled={true} />):(
                                        <Form.Control type="number" placeholder='Emisiones evitadas dentro del sector generación de electricidad'
                                            value={emisiones_evitadas_generacion} onChange={
                                                (e) => {
                                                    setEmisiones_evitadas_generacion(e.target.value);
                                                }
                                            }
                                        />))}
                                    </FloatingLabel>
                                </InputGroup>
                                {estimado[4] !== 0 && (<>
                                        <ProgressBar variant="success" now={emisiones_evitadas_generacion / estimado[4] * 100} label={(emisiones_evitadas_generacion / estimado[4] * 100).toFixed(2) + '%'} />
                                        <small>Estimado: {estimado[4]}</small>
                                        </>)}
                                        <br /><br />
                                <InputGroup className='mb-4'>
                                    <InputGroup.Text>
                                        %
                                    </InputGroup.Text>
                                    <FloatingLabel label="Índice de tecnologías limpias">
                                        {(disabled6?(<Form.Control type="number" placeholder='Índice de tecnologías limpias' disabled={true} />):(
                                        <Form.Control type="number" placeholder='Índice de tecnologías limpias'
                                            value={indice_tecnologias} onChange={
                                                (e) => {
                                                    setIndice_tecnologias(e.target.value);
                                                }
                                            }
                                        />
                                        ))}
                                    </FloatingLabel>
                                </InputGroup>
                                {estimado[5] !== 0 && (<>
                                        <ProgressBar variant="success" now={indice_tecnologias / (estimado[5]*100) * 100} label={(indice_tecnologias / (estimado[5]*100) * 100).toFixed(2) + '%'} />
                                        <small>Estimado: {(estimado[5]*100)}</small>
                                        </>)}
                                        <br /><br />
                                <Form.Group className='mb-4 text-end'>
                                    <Button variant="secondary" type="reset" onClick={handleReset}>
                                        Limpiar
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button variant="primary" type="submit">
                                        <FontAwesomeIcon icon={faSave} /> Guardar
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                {/*<Col lg={4} className='mb-4'>
                    <Card>
                        <Card.Body>
                            <h1>80%</h1>
                        </Card.Body>
                    </Card>
                                        </Col>*/}
            </Row>
        </Container>
    );
};

export { Registro };