import React from 'react';
import { Container, Row, Col, Card, Form, Button, FloatingLabel, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { getUsuario, putUsuario } from '../../Providers/ApiProvider';
import { useNavigate } from 'react-router-dom';

const Perfil = ({ state, setState }) => {
    const navigate = useNavigate();
    const [apodo , setApodo] = React.useState('');
    const [email , setEmail] = React.useState('');
    const [password , setPassword] = React.useState('');
    const [password_confirm , setPasswordConfirm] = React.useState('');
    const [password_error , setPasswordError] = React.useState(false);
    const [message , setMessage] = React.useState('');
    const [passwordLength, setPasswordLength] = React.useState(false);
    const [status , setStatus] = React.useState('');

    React.useEffect(() => {
        if(state.UserId !== null && state.UserId > 0) {
            getUsuario(state.UserId).then((response) => {
                let usuario = response.data;
                setApodo(usuario.apodo);
                setEmail(usuario.email);
                setPassword('');
            });
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let usuario = {
            id: state.UserId,
            apodo: apodo,
            email: email,
            password: password,
            rolId: 0
        };
        putUsuario(usuario).then((response) => {
            setStatus(response.status);
            if(response.status === 200){
                setMessage('Usuario editado correctamente.');
            } else {
                setMessage('No se pudo editar el usuario.');
            }
        });
        window.scrollTo(0, 0);
    };

    React.useEffect(() => {
        if(password !== password_confirm) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }

        if(password.length < 8 && password.length > 0) {
            setPasswordLength(true);
        } else {
            setPasswordLength(false);
        }
    }, [password, password_confirm]);
    

    return (
        <Container>
            {((status === 201 || status === 200) && message !== '') && (
                <Row className='justify-content-md-center'>
                    <Col lg={6} className='mt-4'>
                        <Alert variant='success'>
                            {message}
                        </Alert>
                    </Col>
                </Row>
            )}
            {((status !== 201 && status !== 200) && message !== '') && (
                <Row className='justify-content-md-center'>
                    <Col lg={6} className='mt-4'>
                        <Alert variant='danger'>
                            {message}
                        </Alert>
                    </Col>
                </Row>
            )}
            <Row className='justify-content-md-center mt-4 mb-4'>
                <Col lg={6}>
                    <Card>
                        <Card.Body>
                            <Form>
                                <Form.Group className='mb-4 text-center'>
                                    <FontAwesomeIcon icon={faUserCircle} size="8x" />
                                </Form.Group>
                                <Form.Group className='mb-2'>
                                    <FloatingLabel label="Nombre de usuario">
                                        <Form.Control type="text" placeholder='Nombre de usuario'
                                            value={apodo} onChange={
                                                (e) => {
                                                    setApodo(e.target.value);
                                                }
                                            }
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className='mb-2'>
                                    <FloatingLabel label="Correo electrónico">
                                        <Form.Control type="text" placeholder='Correo electrónico'
                                            value={email} disabled />
                                    </FloatingLabel>
                                </Form.Group>
                                <hr/>
                                <Form.Group className='mb-2'>
                                    <FloatingLabel label="Contraseña">
                                        <Form.Control type="password" placeholder='Contraseña'
                                            value={password} onChange={
                                                (e) => {
                                                    setPassword(e.target.value);
                                                }
                                            }
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className='mb-2'>
                                    <FloatingLabel label="Confirmar contraseña">
                                        <Form.Control type="password" placeholder='Confirmar contraseña'
                                            value={password_confirm} onChange={
                                                (e) => {
                                                    setPasswordConfirm(e.target.value);
                                                }
                                            }
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                                {
                                    password_error &&
                                    <Alert variant='danger'>
                                        Las contraseñas no coinciden.
                                    </Alert>
                                }
                                {
                                    passwordLength &&
                                    <Alert variant='danger'>
                                        La contraseña debe tener al menos 8 caracteres.
                                    </Alert>
                                }
                                <Form.Group className='d-grid gap-2 mb-2'>
                                    <Button variant="primary" className='btn-lg btn-block' type="submit" onClick={handleSubmit}>
                                        Guardar
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Perfil;