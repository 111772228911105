import React from 'react';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import { Col, Row, Table, Container, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faRefresh, faPlus } from '@fortawesome/free-solid-svg-icons';
import { getUsuarios, deleteUsuario } from '../../Providers/ApiProvider';
import { useNavigate } from 'react-router-dom';

const Usuarios = ({ state, setState }) => {
    const navigate = useNavigate();

    const [datos, setDatos] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [usuarioId, setUsuarioId] = React.useState(null);

    const listarUsuarios = () => {
        getUsuarios().then((response) => {
            let usuarios = response.data;
            usuarios.forEach(usuario => {
                usuario.actions = (
                    <div>
                        <button className="btn btn-outline-danger" onClick={() => handleDelete(usuario.id)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                        &nbsp;
                        <button className="btn btn-outline-primary" onClick={() => handleEdit(usuario.id)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                    </div>
                );
            });
            setDatos(usuarios);
        });
    };

    React.useEffect(() => {
        listarUsuarios();
    }, []);

    const handleDelete = (id) => {
        setShowModal(true);
        setUsuarioId(id);
    };

    const handleEdit = (id) => {
        setState({ 
            ...state,
            usuario: id
        });
        return navigate('/usuario');
    };
    
    const handleNew = () => {
        return navigate('/usuario');
    };

    const headers = [
        { title: "Nombre", prop: "apodo", isSortable: true, isFilterable: true },
        { title: "Correo", prop: "email", isSortable: true, isFilterable: true },
        { title: "Acciones", prop: "actions" }
    ];

    const confirmDelete = () => {
        setShowModal(false);
        deleteUsuario(usuarioId).then(() => {
            listarUsuarios();
        });
    };

    const modalDelete = () => {
        return (
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Está seguro que desea eliminar este usuario?</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-secondary" onClick={() => setShowModal(false)}>
                        Cancelar
                    </button>
                    <button className="btn btn-outline-danger" onClick={confirmDelete}>
                        Eliminar
                    </button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <Container>
            <Row>
                <Col className='mt-4 mb-4'>
                    <h1>Usuarios</h1>
                </Col>
                <Col className='text-end mt-4 mb-4'>
                    <button className="btn btn-primary" onClick={handleNew}>
                        <FontAwesomeIcon icon={faPlus} />&nbsp;
                        Nuevo usuario
                    </button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DatatableWrapper
                        headers={headers}
                        body={datos}
                        paginationOptionsProps={{
                            initialState: {
                                options: [
                                    5,
                                    10,
                                    15,
                                    20
                                ],
                                rowsPerPage: 10
                            }
                        }}
                    >
                        <Row className="mb-3">
                            <Col
                                xs={12}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Filter />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                            >
                                <PaginationOptions alwaysShowPagination />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Pagination alwaysShowPagination />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Table striped bordered hover responsive>
                                    <TableHeader />
                                    <TableBody />
                                </Table>
                            </Col>
                        </Row>
                    </DatatableWrapper>
                </Col>
            </Row>
            {modalDelete()}
        </Container>

    );
};

export default Usuarios;