import './App.css';
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { StateProvider, StateContext } from './Providers/StateProvider';
import Login from './Modules/Login/Login';
import Logout from './Modules/Logout/Logout';
import PrivateRoute from './PrivateRoute';
import Dashboard from './Modules/Dashboard/Dashboard';
import { Registro } from './Modules/Registro/Registro';
import Usuarios from './Modules/Usuarios/Usuarios';
import Usuario from './Modules/Usuarios/Usuario';
import Perfil from './Modules/Perfil/Perfil';
import Data from './Modules/Data/Data';
import Archivo from './Modules/Data/Archivo';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <StateProvider>
      <StateContext.Consumer>
        {({ state, setState }) =>(
          <HashRouter>
            <Routes>
              <Route path="/" element={<Navigate to="login" />} />
              <Route path="login" element={<Login setState={setState} />}  />
              <Route path="logout" element={<Logout setState={setState} />} />
              <Route path="dashboard" element={
                <PrivateRoute>
                  <Dashboard state={state} setState={setState} />
                </PrivateRoute>
              } />
              <Route path="data" element={
                <PrivateRoute>
                  <Data state={state} setState={setState} />
                </PrivateRoute>
              } />
              <Route path="archivo" element={
                <PrivateRoute>
                  <Archivo state={state} setState={setState} />
                </PrivateRoute>
              } />
              <Route path="registro" element={
                <PrivateRoute>
                  <Registro state={state} setState={setState} />
                </PrivateRoute>
              } />
              <Route path="usuarios" element={
                <PrivateRoute>
                  <Usuarios state={state} setState={setState} />
                </PrivateRoute>
              } />
              <Route path="usuario" element={
                <PrivateRoute>
                  <Usuario state={state} setState={setState} />
                </PrivateRoute>
              } />
              <Route path="perfil" element={
                <PrivateRoute>
                  <Perfil state={state} setState={setState} />
                </PrivateRoute>
              } />
            </Routes>
          </HashRouter>
        )}
      </StateContext.Consumer>
    </StateProvider>
  );
}

export default App;
