import React from 'react';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

const DataTable = ({ data, handleDelete, handleEdit }) => {
    const [datos, setDatos] = React.useState([]);

    React.useEffect(() => {
        const nuevos_datos = [];

        data.forEach(registro => {
            let fecha = registro.fecha.split('-');
            let fecha_correcta = fecha[1] + '/' + fecha[0];
            let consumo_etanol = registro.consumo_etanol_transporte;
            let consumo_electricidad = registro.consumo_electricidad_transporte;
            let emisiones_evitadas_electromovilidad = registro.emisiones_evitadas_electromovilidad;
            let emisiones_evitadas_etanol = registro.emisiones_evitadas_etanol;
            let emisiones_evitadas_generacion = registro.emisiones_evitadas_generacion_electricidad;
            let indice_tecnologias = registro.indice_tecnologias_limpias;
            let idUsuario = registro.idUsuario;
            let created_at = registro.created_at;
            // Formateamos la fecha
            let fecha_formateada = created_at.split('T');
            created_at = fecha_formateada[0] + ' ' + fecha_formateada[1].split('.')[0];

            let updated_at = registro.updated_at;
            // Formateamos la fecha
            fecha_formateada = updated_at.split('T');
            updated_at = fecha_formateada[0] + ' ' + fecha_formateada[1].split('.')[0];
            let progress = 0;
            let nombre = registro.usuario.apodo;

            // Calculamos el porcentaje
            if (consumo_etanol !== null) progress += 12.5;
            if (consumo_electricidad !== null) progress += 12.5;
            if (emisiones_evitadas_electromovilidad !== null) progress += 12.5;
            if (emisiones_evitadas_etanol !== null) progress += 12.5;
            if (emisiones_evitadas_generacion !== null) progress += 12.5;
            if (indice_tecnologias !== null) progress += 12.5;
            if (idUsuario !== null) progress += 12.5;
            if (fecha !== null) progress += 12.5;

            // incrustamos en nuevos_datos
            nuevos_datos.push({
                fecha: fecha_correcta,
                progress: progress,
                created_at: created_at,
                updated_at: updated_at,
                actions: (
                    <div>
                        <button className="btn btn-outline-danger" onClick={() => handleDelete(registro.id)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button> &nbsp;
                        <button className="btn btn-outline-warning" onClick={() => handleEdit(registro.id)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                    </div>
                ),
                apodo: nombre
            });
            setDatos(nuevos_datos);
        });

    }, [data]);

    const header = [
        { title: "Mes", prop: "fecha", isSortable: true, isFilterable: true },
        { title: "Progreso", prop: "progress", isSortable: true, isFilterable: true },
        { title: "Registó", prop: "apodo", isSortable: true, isFilterable: true },
        { title: "Actualizado", prop: "updated_at", isSortable: true, isFilterable: true },
        { title: "Acciones", prop: "actions" }
    ];

    return (
        <DatatableWrapper
            headers={header}
            body={datos}
            paginationOptionsProps={{
                initialState: {
                    options: [
                        5,
                        10,
                        15,
                        20
                    ],
                    rowsPerPage: 10
                }
            }}
        >
            <Row className="mb-3">
                <Col
                    xs={12}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                >
                    <Filter />
                </Col>
                <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                >
                    <PaginationOptions alwaysShowPagination />
                </Col>
                <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                >
                    <Pagination alwaysShowPagination />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Table striped bordered hover responsive>
                        <TableHeader />
                        <TableBody />
                    </Table>
                </Col>
            </Row>
        </DatatableWrapper>
    );
};

export default DataTable;