import { Container, Row, Col, Button } from "react-bootstrap";
import { Navigate, Link } from "react-router-dom";
import { signout } from "../../Sesion";

const Logout = () => {
    signout();
    return (
        <Container>
            <Row className="row vh-100 align-items-center justify-content-center">
                <Col lg={3} className="text-center">
                    <h1>Hasta pronto</h1>
                    <p>Gracias por usar el sistema de MRV</p>
                    <Link className="btn btn-primary" to={"/login"}>Iniciar Sesi&oacute;n</Link>
                </Col>
            </Row>
        </Container>
    );
}

export default Logout;