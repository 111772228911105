// Este es un provider que obtiene el estado inicial de la aplicación desde el localStorage y
// lo guarda en el estado de la aplicación.

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from '../Hooks/useLocalStorage';

const StateContext = createContext();

const StateProvider = ({ children }) => {
    const [state, setState] = useLocalStorage('state', {
        Token: "",
        UserName: "",
        UserId: 0,
        Logged: false,
        Role: "",
        registro_id: 0,
        registro_actual: null,
        usuario_id: 0,
        usuario_actual: null,
        registros: [],
        data: [],
        indicador1: true,
        indicador2: true,
        indicador3: true,
        indicador4: true,
        indicador5: true,
        indicador6: true,
    });

    return (
        <StateContext.Provider value={{ state, setState }}>
            {children}
        </StateContext.Provider>
    );
}

export { StateProvider, StateContext };
