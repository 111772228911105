import React from 'react';
import { Container, Row, Col, Card, Form, InputGroup, Button, FloatingLabel, Alert, ListGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faQuestionCircle, faArrowLeft, faEye, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import { getTags, getTag, deleteTag } from '../../Providers/ApiProvider';
import DataTable from "../../Components/DataTable/DataTable";
import { useNavigate } from "react-router-dom";

const Data = () => {
    const [tags, setTags] = React.useState([]);
    const [tag, setTag] = React.useState('');
    const [tagData, setTagData] = React.useState([]);
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [success, setSuccess] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [tabla, setTabla] = React.useState(null);
    const [columnas, setColumnas] = React.useState([]);
    const [showModal, setShow] = React.useState(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        getTags().then(response => {
            if(response.status === 200) {
                if(response.data.length > 0) {
                    /*
                        Debemos revisar que las tags no se repitan antes de asignarlas en el state
                        Si la tag ya existe, no la agregamos
                        sin embargo, si la diferencia de tiempo de creacion es mayor a 1 minuto, la agregamos
                    */
                    let tags = [];
                    response.data.forEach(tag => {
                        let index = tags.findIndex(t => t.tag === tag.tag);
                        if(index === -1) {
                            // Cambiar el formato de la fecha a dd/mm/yyyy hh:mm
                            let fecha = tag.created_at.split('T');
                            let fecha_formateada = fecha[0] + ' ' + fecha[1].split('.')[0];
                            tag.created_at = fecha_formateada;
                            tags.push(tag);
                        } else {
                            let fecha1 = new Date(tag.created_at);
                            let fecha2 = new Date(tags[index].created_at);
                            let diferencia = fecha1.getTime() - fecha2.getTime();
                            if(diferencia > 10000) {
                                // Cambiar el formato de la fecha a dd/mm/yyyy hh:mm
                                let fecha = tag.created_at.split('T');
                                let fecha_formateada = fecha[0] + ' ' + fecha[1].split('.')[0];
                                tag.created_at = fecha_formateada;
                                tags.push(tag);
                            }
                        }
                    });
                    setTags(tags);
                } else {
                    setTags([]);
                    setError(true);
                    setErrorMessage('No hay archivos registrados.');
                }
                setLoading(false);
            }
        });
    }, []);

    React.useEffect(() => {
        if(tagData.length > 0) {
            handleFile();
        }
    }, [tagData]);

    const handleNew = () => {
        navigate('/archivo');
    }

    const handleFile = () => {
        // Y debe desplegarse en una striped table en donde columna, son las columnas, fila son las filas y data es el contenido de la celda
        let columnas = [];
        let filas = [];
        let datos = [];
        let tabla = [];
        
        // Obtenemos las columnas
        tagData.forEach(registro => {
            if(!columnas.includes(registro.columna)) {
                columnas.push(registro.columna);
            }
        });
        // Obtenemos las filas
        tagData.forEach(registro => {
            if(!filas.includes(registro.fila)) {
                filas.push(registro.fila);
            }
        });

        // Obtenemos los datos
        tagData.forEach(registro => {
            datos.push({
                fila: registro.fila,
                columna: registro.columna,
                data: registro.data,
            });
        });

        // Creamos la tabla
        filas.forEach(fila => {
            let fila_actual = [];
            columnas.forEach(columna => {
                let data = datos.find(dato => dato.fila === fila && dato.columna === columna);
                if(data) {
                    fila_actual.push(data.data);
                } else {
                    fila_actual.push('');
                }
            });
            tabla.push(fila_actual);
        });
        // esta tabla se debe mostrar en una tabla html del tipo striped table asi que debe adaptarse a ese formato, obviar la primera fila
        let tabla_html = [];
        tabla.forEach((fila, index) => {
            if(index !== 0) {
                tabla_html.push(
                    <tr key={index}>
                        {fila.map((columna, index) => (
                            <td key={index}>{columna}</td>
                        ))}
                    </tr>
                );
            }
        });
        setTabla(tabla_html);
        setColumnas(columnas);
    }

    const handleShowFile = (tag) => {
        getTag(tag).then(response => {
            if(response.status === 200) {
                setTagData(response.data);
                setTag(tag);
            } else {
                setError(true);
                setErrorMessage('Ha ocurrido un error, por favor, contacta con el proveedor.');
            }
        });
    }

    const handleDeleteConfirm = () => {
        // Hacemos el delete
        deleteTag(tag).then(response => {
            if(response.status === 200) {
                // Eliminamos el tag del state
                let Tags = tags.filter(t => t.tag !== tag);
                setTags(Tags);
                setSuccess(true);
                setSuccessMessage('Archivo eliminado correctamente.');
            } else {
                setError(true);
                setErrorMessage('Ha ocurrido un error, por favor, contacta con el proveedor.');
            }
        });
        setShow(false);
    };

    const handleClose = () => {
        setTag('');
        setShow(false);
    };

    const handleDelete = () => {
        return (
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Estás seguro de eliminar el archivo {tag}?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirm}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <Container>
            <Row className="justify-content-center mt-4 mb-4">
                <Col lg={12}>
                    <Button variant="primary" onClick={handleNew}>
                        + Nuevo archivo
                    </Button>
                </Col>
            </Row>
            <Row className="justify-content-center mt-4 mb-4">
                <Col lg={12} className="text-center">
                    {(loading) ? 'Cargando...' : (error) ?
                    (<Alert variant="danger">
                            {errorMessage}
                        </Alert>) : (success) ?
                        (<Alert variant="success">
                            {successMessage}
                        </Alert>) : (<></>)}
                    
                </Col>
            </Row>
            <Row className="justify-content-center mt-4 mb-4">
                <Col lg={4} className="text-center">
                    {(tags.length > 0) ?
                    (<>
                        <ListGroup>
                            {tags.map((tag, index) => (
                                <ListGroup.Item key={index} className=''>
                                    {tag.tag}&nbsp;&nbsp;-&nbsp;&nbsp;
                                    {tag.created_at}
                                    &nbsp;
                                    <Button variant="outline-primary" size="sm" onClick={() => handleShowFile(tag.tag)}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </Button>&nbsp;
                                    <Button variant="outline-danger" size="sm" onClick={() => {
                                        setTag(tag.tag);
                                        setShow(true);
                                    }}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>

                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </>) : (<></>)}
                </Col>
                <Col lg={8} className="text-center">
                    {(tabla) ? (<>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        {columnas.map((columna, index) => (
                                            <th key={index} scope="col">{columna}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tabla}
                                </tbody>
                            </table>
                        </div>
                    </>)
                    : (<>
                        <Alert variant="info">
                            Selecciona un archivo para ver su contenido.
                        </Alert>
                    </>)}
                </Col>
            </Row>
            {handleDelete()}
        </Container>
    );
};

export default Data;